export const transformSecondsToDate = (seconds: number): Date => {
  const date = new Date(0, 0, 0, 0, 0)
  const { hours, minutes, seconds: secs } = transformSecondsToHoursMinutesSeconds(seconds)
  date.setHours(Number(hours))
  date.setMinutes(Number(minutes))
  date.setSeconds(Number(secs))
  return date
}

export const transformDateToSeconds = (date: Date): number => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return hours * 3600 + minutes * 60 + seconds
}

export const transformSecondsToHoursAndMinutes = (
  secondsDuration: number,
): { hours: string; minutes: string } => {
  const hours = Math.floor(secondsDuration / (60 * 60))
  const minutes = Math.floor((secondsDuration % (60 * 60)) / 60)
  return {
    hours: hours < 10 ? `0${hours}` : String(hours),
    minutes: minutes < 10 ? `0${minutes}` : String(minutes),
  }
}

export const transformSecondsToHoursMinutesSeconds = (
  secondsDuration: number,
): { hours: string; minutes: string; seconds: string } => {
  const { hours, minutes } = transformSecondsToHoursAndMinutes(secondsDuration)
  const seconds = secondsDuration % 60
  return {
    hours,
    minutes,
    seconds: seconds < 10 ? `0${seconds}` : String(seconds),
  }
}
