import { FC } from 'react'
import { default as ReactDatePicker } from 'react-datepicker'
import {
  transformDateToSeconds,
  transformSecondsToDate,
} from 'shared/components/date-picker/time-picker/utils'
import FormInput, { InputProps } from 'shared/components/form-input'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { generatePlaceholderFromFormat } from '../utills/generate-placeholder-for-format'
import { transformSioDateFormatToDatePicker } from '../utills/transform-sio-date-format-to-date-picker'

type Props = Omit<InputProps, 'value' | 'onChange' | 'type'> & {
  seconds: number | null
  onChangeSeconds: (seconds: number) => void
}

export const TimePicker: FC<Props> = ({ seconds, onChangeSeconds, ...props }) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()

  const { timeFormat } = user ? transformSioDateFormatToDatePicker(user) : { timeFormat: 'HH:mm' }

  return (
    // react-datepicker create a popup component as sibling which can cause "jumping" if parent has flex property
    <div className={'w-min'}>
      <ReactDatePicker
        disabled={props.disabled || props.isPreFetching}
        selected={typeof seconds === 'number' ? transformSecondsToDate(seconds) : null}
        onChange={date => {
          if (date) {
            onChangeSeconds(transformDateToSeconds(date))
          }
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption={t('global.time')}
        timeFormat={timeFormat}
        dateFormat={timeFormat}
        placeholderText={generatePlaceholderFromFormat(timeFormat)}
        customInput={<FormInput className={'w-32'} {...props} />}
        timeClassName={time =>
          seconds === transformDateToSeconds(time) ? 'text-blue' : 'text-darkblue'
        }
        wrapperClassName={'time-only'}
      />
    </div>
  )
}
