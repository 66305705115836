import { TLocoType } from 'shared/hooks/use-loco-translation'
import { TIME_LABELS, TimeType, TimeTypeState } from './constants'

export const createTimeTypeOptionsForSelect = (t: TLocoType): { id: string; caption: string }[] =>
  Object.entries(TimeType).map(([key, value]) => ({
    id: value,
    caption: t(TIME_LABELS[value]),
  }))

export const convertTimeValueToSeconds = (
  timeValue: number,
  timeType: TimeType,
  timeTypeState?: TimeTypeState,
): number => {
  const timeValueWithSign = timeTypeState === TimeTypeState.before ? timeValue * -1 : timeValue

  switch (timeType) {
    case TimeType.MINUTES:
      return timeValueWithSign * 60
    case TimeType.HOURS:
      return timeValueWithSign * 60 * 60
    case TimeType.DAYS:
      return timeValueWithSign * 60 * 60 * 24
  }
}

export const convertSecondsToTimeValue = (
  seconds: number,
): {
  timeValue: number
  timeType: TimeType
  beforeAfter: TimeTypeState
  name: string
} => {
  const BEFORE_AFTER = seconds < 0 ? TimeTypeState.before : TimeTypeState.after
  const absoluteSeconds = Math.abs(seconds)

  const DAYS_SECONDS_COEFFICIENT = 60 * 60 * 24
  const HOURS_SECONDS_COEFFICIENT = 60 * 60
  const MINUTES_SECONDS_COEFFICIENT = 60

  if (!(absoluteSeconds % DAYS_SECONDS_COEFFICIENT) && absoluteSeconds / DAYS_SECONDS_COEFFICIENT)
    return {
      timeValue: Math.round(absoluteSeconds / DAYS_SECONDS_COEFFICIENT),
      timeType: TimeType.DAYS,
      beforeAfter: BEFORE_AFTER,
      name: 'global.name_of_times.days.plural',
    }
  else if (
    !(absoluteSeconds % HOURS_SECONDS_COEFFICIENT) &&
    absoluteSeconds / HOURS_SECONDS_COEFFICIENT
  ) {
    return {
      timeValue: Math.round(absoluteSeconds / HOURS_SECONDS_COEFFICIENT),
      timeType: TimeType.HOURS,
      beforeAfter: BEFORE_AFTER,
      name: 'global.name_of_times.hours.plural',
    }
  } else {
    return {
      timeValue: Math.round(absoluteSeconds / MINUTES_SECONDS_COEFFICIENT),
      timeType: TimeType.MINUTES,
      beforeAfter: BEFORE_AFTER,
      name: 'global.name_of_times.minutes.plural',
    }
  }
}
