import { Switch } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface CheckBoxSwitcherProps {
  selected: boolean
  onChange: (value: boolean) => void
  label?: string | React.ReactNode
  labelClassName?: string
  wrapperClassName?: string
  disabled?: boolean
}

const CheckBoxSwitcher = ({
  selected,
  onChange,
  label,
  labelClassName,
  wrapperClassName,
  disabled,
}: CheckBoxSwitcherProps) => {
  return (
    <div className={twMerge('relative flex md:flex-row flex-col gap-2', wrapperClassName)}>
      <Switch
        checked={selected}
        onChange={onChange}
        disabled={disabled}
        className={`${selected && !disabled ? 'bg-blue' : 'bg-gray-100'}
          relative inline-flex w-10 h-5 shrink-0 cursor-pointer rounded-full border-2 border-transparent main-transition-colors focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden="true"
          className={`${selected ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition-transform duration-200 ease-linear`}
        />
      </Switch>
      {label && <div className={`${labelClassName}`}>{label}</div>}
    </div>
  )
}

export default CheckBoxSwitcher
