export const generatePlaceholderFromFormat = (timeFormat: string): string => {
  const formatMap: Record<string, string> = {
    h: '-',
    hh: '--',
    H: '-',
    HH: '--',
    m: '-',
    mm: '--',
    s: '-',
    ss: '--',
    a: 'AM/PM',
  }

  return timeFormat
    .split(/([^\w])/g)
    .map(token => formatMap[token] || token)
    .join('')
}
