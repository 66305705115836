export const transformSioDateFormatToDatePicker = ({
  timeFormat,
  dateFormat,
}: {
  timeFormat: string
  dateFormat: string
}) => {
  const dateMapping = {
    d: 'dd', // Day of the month, 2 digits with leading zero
    m: 'MM', // Month, 2 digits with leading zero
    Y: 'yyyy', // Year, 4 digits
  }

  const timeMapping = {
    h: 'hh', // Hour in 12-hour format, 2 digits with leading zero
    H: 'HH', // Hour in 24-hour format, 2 digits with leading zero
    i: 'mm', // Minutes, 2 digits with leading zero
    s: 'ss', // Seconds, 2 digits with leading zero
    A: 'a', // AM/PM marker
  }

  const transformFormat = (format: string, mapping: Record<string, string>) => {
    return format
      .split(/([ :/])/)
      .map(part =>
        part
          .split('')
          .map(char => mapping[char] || char)
          .join(''),
      )
      .join('')
  }

  const transformedTimeFormat = transformFormat(timeFormat, timeMapping)
  const transformedDateFormat = transformFormat(dateFormat, dateMapping)

  return {
    timeFormat: transformedTimeFormat,
    dateFormat: transformedDateFormat,
  }
}
