import LocalizeNamespaces from 'shared/constants/localize-namespaces.mjs'

export enum TimeType {
  DAYS = 'day',
  HOURS = 'hour',
  MINUTES = 'minute',
}

export enum TimeTypeState {
  before = 'before',
  after = 'after',
}

export const TIME_LABELS: Record<TimeType, string> = {
  [TimeType.DAYS]: 'dashboard.name_of_times.days.plural',
  [TimeType.HOURS]: 'dashboard.name_of_times.hours.plural',
  [TimeType.MINUTES]: 'dashboard.name_of_times.minutes.plural',
}

export const TimeDelayPickerLoco = [LocalizeNamespaces.name_of_times]
