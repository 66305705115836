import React, { memo, useMemo } from 'react'
import FormInputIntegerPositiveNumbers from 'shared/components/form-input/form-input-integer-positive-numbers'
import FormSelectInline from 'shared/components/form-select/form-select-inline'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { TimeType, TimeTypeState } from './constants'
import { createTimeTypeOptionsForSelect } from './utils'

export type TimeDelayPickerProps = Omit<React.HTMLProps<HTMLLabelElement>, 'onChange'> &
  FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    selectedTimeValue: number
    selectedTimeType: TimeType
    selectedTimeBeforeAfter?: TimeTypeState
    onChange: (
      selectedTimeValue: TimeDelayPickerProps['selectedTimeValue'],
      selectedTimeType: TimeDelayPickerProps['selectedTimeType'],
      selectedTimeBeforeAfter?: TimeDelayPickerProps['selectedTimeBeforeAfter'],
    ) => void
    classNameInputInteger?: string
    withTimeAfterBeforeSelect?: boolean
    isPreFetching?: boolean
  }

const TimeDelayPicker = memo(function TimeDelayPicker({
  description,
  error,
  selectedTimeValue,
  selectedTimeType,
  onChange,
  labelClassName,
  className,
  label,
  required,
  selectedTimeBeforeAfter,
  withTimeAfterBeforeSelect,
  classNameInputInteger,
  isPreFetching,
  ...props
}: TimeDelayPickerProps) {
  const { t } = useLocoTranslation()

  const timeStates = [
    { id: TimeTypeState.before, caption: t('dashboard.funnel.emails_webinar_start_before') },
    { id: TimeTypeState.after, caption: t('dashboard.funnel.emails_webinar_start_after') },
  ]

  const typeTypeOptions = useMemo(() => createTimeTypeOptionsForSelect(t), [])

  return (
    <label {...props} className={`text-sm font-medium flex flex-col gap-1 ${className}`}>
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      <div className={`flex justify-start gap-4 `}>
        {withTimeAfterBeforeSelect && (
          <FormSelectInline
            withoutVirtualization
            withoutCloseIcon
            data={timeStates}
            isPreFetching={isPreFetching}
            value={selectedTimeBeforeAfter}
            onChange={value =>
              onChange(selectedTimeValue, selectedTimeType, value as TimeTypeState)
            }
          />
        )}
        <FormInputIntegerPositiveNumbers
          className={`w-3/12 ${classNameInputInteger}`}
          value={selectedTimeValue}
          onChange={value => onChange(value, selectedTimeType, selectedTimeBeforeAfter)}
          isPreFetching={isPreFetching}
        />
        <FormSelectInline
          withoutVirtualization
          withoutCloseIcon
          data={typeTypeOptions}
          value={selectedTimeType}
          isPreFetching={isPreFetching}
          onChange={value =>
            onChange(selectedTimeValue, value as TimeType, selectedTimeBeforeAfter)
          }
        />
      </div>
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
})

export default TimeDelayPicker
